import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import CustomFormGroup from "../../common/customFormGroup";
import { useHistory } from "react-router-dom";
import circleLogo from "../../../assets/images/circle-logo.png";
import logo from '../../../assets/images/logo.png'
import { useDispatch, useSelector } from "react-redux"
import { toast } from '../../../utils'
import * as episodesActions from './action'
import { getQuestionsFromSelectedCategory } from '../Spinner/api'
import { logOut } from '../../../utils';
import moment from "moment";
import {
    FormControl, Select, InputLabel, MenuItem, FormControlLabel, Checkbox, FormGroup, Button, TableCell, TableRow,
    Table, TableContainer, TableBody, TextField
} from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ws } from "../../../ws";
import loader from '../../../assets/images/loader.gif'
import { StoreRounded } from "@material-ui/icons";
export default function Episodes(props) {
    const history = useHistory();
    const dispatch = useDispatch()
    const [episode, setEpisode] = useState('')
    const [selected, setSelected] = useState(false)
    const [epis, setEpis] = useState('')
    const router = useHistory()

    const { episodes = [], isLoading } = useSelector(state => state.episodes || []);

    // useEffect(() => {
    //     let id = localStorage.getItem('id')
    //     if (id)
    //         history.push('/gifts')
    // }, [])
    useEffect(() => {
        console.log('hhhhhhhhhhhh')
    }, [])

    useEffect(() => {
        if (moment().valueOf() == 1648105333857) {
            console.log('yyyyyyyyyyyyyyyyyy')
            logOut("top-challenge-token")
            logOut('id')
            logOut("loggedInAs")
            localStorage.clear()
            router.push('/')
        }
    })


    const channelName = [epis && epis.length ? epis.map((item) => {
        return item.episode
    }) : []]


    let startTimeInStringFormat = epis && epis.length ? epis.map((item) => {
        return item.start_time
    }) : []

    let splittedString = String(startTimeInStringFormat[0]).replace(/:[^:]*$/, '')


    let startTime = epis && epis.length ? epis.map((item) => {
        return item.start_time_ts
    }) : []

    let endTime = epis && epis.length ? epis.map((item) => {
        return item.end_time_ts
    }) : []


    const tonext = (err) => {

        if (moment().valueOf() >= startTime[0] || moment().valueOf() <= endTime[0]) {

            const { channel_id, id } = epis && epis[0] || {}
            if (!channelName.length) {
                toast.error((err && err.message) || 'Please Select an episode')
            } else {
                history.push(`/gifts`);
            }
            ws.send(JSON.stringify({
                action: "onMessage",
                // message: "spin the rotation"
                message: JSON.stringify({ data: { channel_id, id } })
            }));

        } else {
            toast.error(`Episode will start at: ${splittedString} Hrs`)
        }

    };
    const handleChange = (e, value) => {
        setSelected(true)
        //setEpisode(value)

    }

    let endDate

    const getEpisodesData = () => {
        dispatch(episodesActions.getAllEpisodes())
            .then(res => {
                setEpis(res.episodes)
                localStorage.setItem('id', res.episodes && res.episodes.map((item) => item.id))
                localStorage.setItem('channel_id', res.episodes && res.episodes.map((item) => item.channel_id))
                return res
            }).then(response => {
                // endDate = response.episodes.length && response.episodes.map(item => item.end_time_ts).join('.')
                // if (endDate < moment().valueOf()) {
                //     logOut("top-challenge-token")
                //     logOut('id')
                //     logOut("loggedInAs")
                //     localStorage.clear()
                //     router.push('/')
                // }
                console.log(response)
            })
    }

    const episodesOptions = channelName && channelName.map((item) => ({ title: `${item}` }))

    useEffect(() => {
        getEpisodesData()
    }, [])

    const handleLogout = () => {
        logOut("top-challenge-token")
        logOut('id')
        logOut("loggedInAs")
        localStorage.clear()
        router.push('/')
    }
    if (isLoading) return <img src={loader} height='200px' style={{
        display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center', marginTop: '15%', marginLeft: '43%'
    }} />
    return (
        <div className="main_container">
            <div className="spin-button-logout">
                <button onClick={() => handleLogout()} >Logout </button>
            </div>
            <div className="form-container-inner">
                <div className="form-container-con">
                    <Grid className="form_Container">
                        <div className="cus-circle-logo">
                            <img src={circleLogo} />
                        </div>
                        <div className="form-heading">
                            <h2>Current Episode</h2>
                        </div>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="cus-field-rep">
                                {(channelName[0].length) ? <CustomFormGroup
                                    showEndAdorment={false}
                                    id="grouped-demo"
                                    options={episodesOptions}
                                    onChange={handleChange}
                                    value={channelName}
                                    disabled={true}
                                    // groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.title}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Episodes" variant="outlined" />}
                                /> :
                                    <Typography variant="h5">
                                        NO EPISODE AVAILABLE YET.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <button disabled={!channelName.length || isLoading} className={`login_button ${!channelName[0].length || isLoading ? 'disabled' : ''}`} onClick={() => tonext()}>Submit</button>
                        </Grid>
                    </Grid>
                    <div className="login-bottom-logo">
                        <img src={logo} className="login_logo_image" />
                    </div>
                </div>
            </div>
        </div >
    );
}

