import React from 'react';
import logo from './logo.svg';
import './App.css';
import SpinnerGift from './Components/Pages/Spinner/spinners/GiftsSpinner'
import Gifts from './Components/common/gifts'
import Login from './Components/Pages/Login/loginScreen'
import SpinnerText from './Components/Pages/Spinner/spinners/WinnersSpinner'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import SecondSpinner from './Components/Pages/Spinner/spinners/MainSpinner';
import Screen from './Components/common/Screen'
import Episodes from './Components/Pages/Episodes/episodes'
import { useEffect } from 'react';
import { ws } from './ws';
import { getObject } from './utils';
import PageNotFound from './Components/common/PageNotFound/PageNotFound';


function isAuth() {
  let result: any = getObject('top-challenge-token');
  let { access_token = '' } = JSON.parse(result) || {}
  return access_token;
}

//Public routes
function PublicRoute(props) {
  const { component: Component, restricted = false, path, ...rest } = props

  const render = props => {
    console.log(props, 'props>>>>>>');

    if (isAuth()) {
      return <Redirect to="/episodes" />
    }
    return <Component {...props} />
  }
  return <Route {...rest} render={render} />
}

//Private routes
function PrivateRoute(props) {

  const { component: Component, ...rest } = props;

  //Auth check
  const render = props => {
    if (!isAuth()) {
      return <Redirect to="/" />
    }
    return <Component {...props} />
  }
  return <Route {...rest} render={render} />
}


function App(props: any) {



  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PrivateRoute exact path="/spinnerGift" component={SpinnerGift} />
        <PrivateRoute exact path="/gifts" component={Gifts} />
        <PrivateRoute exact path="/spinnerText" component={SpinnerText} />
        <PrivateRoute exact path="/second" component={SecondSpinner} />
        <PrivateRoute exact path="/episodes" component={Episodes} />
        <Route exact path="/screen" component={Screen} />
        <Route exact component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
