
import { ws } from '../../ws';

export const SET_SOCKET_DATA = "SET_SOCKET_DATA"
export const SET_QUESTIONS_DATA = "SET_QUESTIONS_DATA"

export const setSocketData = (data) => {
    return dispatch => {
        dispatch({
            type: SET_SOCKET_DATA,
            data: data
        })
    }
}

export const setQuestionData = (data) => {
    return dispatch => {
        dispatch({
            type: SET_QUESTIONS_DATA,
            data: data
        })
    }
}




export const IS_SOCKET_CONNECTED = 'IS_SOCKET_CONNECTED'

export const setSocketConnetion = (data) => {
    return dispatch => {
        dispatch({
            type: IS_SOCKET_CONNECTED,
            data: data
        })
    }
}




// export const IS_SOCKET_REQUESTED = 'IS_SOCKET_REQUESTED'
// export const IS_SOCKET_CONNECTED = 'IS_SOCKET_CONNECTED'
// export const IS_SOCKET_FAILED = 'IS_SOCKET_FAILED'

// export function setSoocketConnetion(data) {
//     return dispatch => {
//         dispatch({
//             type: IS_SOCKET_REQUESTED,
//             data: data
//         })
//         return new Promise((res, rej) => {
//             console.log('indide promise')

//             ws.onopen = (data) => {
//                 dispatch({
//                     type: IS_SOCKET_CONNECTED,
//                     data: data
//                 })
//                 return res(data)
//             };

//             ws.onerror = function (event) {
//                 dispatch({
//                     type: IS_SOCKET_FAILED,
//                     data: event
//                 })
//                 return rej(event)

//             };

//         })

//     }
// }