import axios from "axios";
import { apiUrl } from "./constant";
import qs from "querystring";
import moment from "moment";
import ToastServive from 'react-material-toast';

export const toast = ToastServive.new({
  place: 'bottomRight',
  duration: 4,
  maxCount: 1
});

const slow = false;

function showSlowNetworkError() {
  setTimeout(() => {
    let networkElement = document.getElementsByClassName("bottomRight-notification-notice");
    if (networkElement && !networkElement.length)
      toast.error("Oops! Please check your internet connection.");
  }, 1000)
}

export function generateUrl(path) {
  if (path.includes("http")) {
    return path;
  }
  return apiUrl + path;
}

export function apiReq(
  endPoint,
  data,
  method,
  headers,
  requestOptions = {},
  config = {}
) {

  // setInternetConnectivity();
  return new Promise((res, rej) => {
    headers = {
      ...getHeaders(),
      ...headers,
    };

    if (method == "get" || method == "delete") {
      data = {
        ...requestOptions,
        params: { ...data },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
        headers,
      };
    }

    let updatedData = data instanceof FormData ? data : Array.isArray(data) ? data : { ...data };

    axios.defaults.timeout = 60000;
    axios[method](endPoint, updatedData, { headers, ...config })
      .then((result) => {
        let { data, status } = result;
        if (status === 200) {
          return res(data);
        }
        return rej(data);
      })
      .catch((err) => {
        if (!err.response) {
          showSlowNetworkError();
          return rej(err);
        }
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message &&
          (err.response.data.message === "Token has expired" || err.response.data.message === "Invalid token!")
        ) {
          logOut('top-challenge-token');
          logOut("loggedInAs")
          window.location.href = "/login";
          return;
        }
        return rej((err && err.response && err.response.data) || {});
      });
  });
}

export function apiPost(endPoint, data, headers = {}, config = {}) {
  return apiReq(generateUrl(endPoint), data, "post", headers, {}, config);
}

export function apiDelete(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "delete", headers);
}

export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(generateUrl(endPoint), data, "get", headers, requestOptions);
}

export function apiPut(endPoint, data, headers = {}) {
  return apiReq(generateUrl(endPoint), data, "put", headers);
}

export function isLoggedIn(userType) {
  let session = getObject(userType) || {};
  session = Object.keys(session).length && JSON.parse(session);
  let accessToken = (session && session.token) || "";
  return accessToken;
}

export function getHeaders() {
  let token = getObject('access_token') || ''
  return {
    Authorization: `Bearer ${token || null}`,
  };
}

export function getObject(key) {
  if (window && window.localStorage) {
    return window.localStorage.getItem(key);
  }
}

export function saveObject(key, value) {
  if (window && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
}

export function multiPartData(data) {
  let multiPart = new FormData();

  for (let prop in data) {
    multiPart.append(prop, data[prop]);
  }

  return multiPart;
}

export function logOut(userType) {
  return new Promise((res, rej) => {
    localStorage.removeItem(userType);
    res(true);
  });
}


export function rememberMe(checked, data) {
  if (checked)
    saveObject('remember_me', window.btoa(JSON.stringify(data)))
  else {
    const remember = getObject(`remember_me`);
    if (remember) localStorage.removeItem(`remember_me`);
  }
}







export function formatString(string = '') {
  string = string && string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  return string || '';
}


export function timeFormat(data) {
  return moment(data).format('MMM DD, YYYY hh:mm:ss A') || ''
}

export function ExpirytimeFormat(data) {
  return moment(data).format('MM/YY') || ''
}
