import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import logo from "../../assets/images/logo.png";
import Grid from "@material-ui/core/Grid";
import categories from "../../assets/images/categories.svg";
import giftbox from "../../assets/images/giftbox.svg";
import jury from "../../assets/images/jury.svg";
import { useHistory } from "react-router-dom";
// const WebSocket = require('isomorphic-ws')
import { w3cwebsocket as W3CWebSocket } from "websocket";
// SOCKET URL 
import * as commonActions from "../../Redux/action/common"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { ws } from '../../ws';
import { logOut } from '../../utils'
import * as episodesActions from '../Pages/Episodes/action'
import moment from "moment";

function Gifts(props) {
  const ref = useRef(70)
  const history = useHistory();
  const dispatch = useDispatch()


  const { socketConnected = false } = useSelector(state => state.common)


  const { search = '' } = useLocation()


  const [spinWheelRotate, setSpinWheelRotate] = useState(false)
  const [iconClick, setIconClick] = useState(false)

  const [response, setResponse] = useState("");
  const [active, setActive] = useState(true)
  const [epiId, setEpiId] = useState(false)
  const [epis, setEpis] = useState('')

  const [activation, setActivation] = useState(true)
  const [type, setType] = useState('')
  const [question, setQuestion] = useState(false)
  const { questions } = useSelector(state => state.common)

  const getType = search && search.split('=')[1] || ''

  let episode_id = localStorage.getItem('id')
  useEffect(() => {
    if (episode_id == "null") {
      history.push('/episodes')
    }
  }, [])

  useEffect(() => {
    if (ws.readyState == ws.OPEN) {
      dispatch(commonActions.setSocketConnetion(true))
    }
    ws.onopen = () => {
      dispatch(commonActions.setSocketConnetion(true))
    };
  }, [])

  useEffect(() => {
    setInterval(() => {
      ws.send(JSON.stringify({
        action: "onMessage",
        message: JSON.stringify({ data: "reconnecting" })
      }));
    }, 20000)
  }, [])

  useEffect(() => {
    setSpinWheelRotate(socketConnected)
  }, [socketConnected])

  useEffect(() => {
    if (questions && questions.type) {
      setType(questions && questions.type || "")
    }
    ws.onmessage = (data) => {
      const getData = data && data.data || ''
      const newData = JSON.parse(getData)

      dispatch(commonActions.setQuestionData(newData))

    }

  }, [questions])

  // useEffect(() => {
  //   ws.onmessage = (data) => {
  //     let socketData = JSON.parse(data.data)
  //     console.log(socketData.data, 'data>>>>>>');
  //     if (socketData.data == 'id not match' || socketData.data == 'reconnecting') {
  //       setEpiId(true)
  //     }
  //   };
  // }, [])






  useEffect(() => {
    if (questions && questions.data == "active buttons") {
      setQuestion(true)
      setActivation(!activation)
      setActive(true)

    }
  }, [questions])



  const activationHandler = () => {
    setActive(!active)
    setActivation(!activation)
    setTimeout(() => {
      setActivation(activation)
      setActive(active)
    }, 70000)
  }


  const spinWheel = () => {
    ws.send(JSON.stringify({
      action: "onMessage",
      message: JSON.stringify({ data: "spin the rotation", id: episode_id })
    }));
    activationHandler()
  }


  const juryWheel = () => {
    ws.send(JSON.stringify({
      action: "onMessage",
      message: JSON.stringify({ data: "spin the jurryWheel", id: episode_id })
    }));
    activationHandler()
  }


  const giftWheel = () => {
    ws.send(JSON.stringify({
      action: "onMessage",
      message: JSON.stringify({ data: "spin the giftWheel", id: episode_id })
    }));
    activationHandler()
  }

  let endDate

  useEffect(() => {
    dispatch(episodesActions.getAllEpisodes())
      .then(res => {
        setEpis(res.episodes)
        return res
      }).then(response => {
        console.log(response)
        // endDate = response.episodes.length && response.episodes.map(item => item.end_time_ts).join('.')
        // if (endDate < moment().valueOf()) {
        //   logOut("top-challenge-token")
        //   logOut('id')
        //   logOut("loggedInAs")
        //   localStorage.clear()
        //   history.push('/')
        // }
      })
  }, [])

  const chanId = epis && epis.length ? epis.map((item) => {
    return item.channel_id
  }) : []

  const id = epis && epis.length ? epis.map((item) => {
    return item.id
  }) : []

  const redirectionToSpinner = (type) => {
    setIconClick(true)
    ws.send(JSON.stringify({
      action: "onMessage",
      message: JSON.stringify({ type, data: { channel_id: chanId[0], id: id[0] } })
    }));
    // activationHandler()
  }
  const handleLogout = () => {
    logOut("top-challenge-token")
    logOut("loggedInAs")
    history.push('/')
  }

  return (
    <div className="main_container">
      <div className="spin-button-logout">
        <button onClick={() => handleLogout()} >Logout </button>
      </div>
      <div className="cus-logo-sec">
        <img src={logo} className="logo_image" />
      </div>
      <div className="container-inner">
        <Grid container>
          <Grid item xs={12} className="p-top-30 form-group">
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="cus-circle-inner">
                    <div className="circle_grid">
                      <div className="circle-icon">
                        <img src={categories} onClick={() => redirectionToSpinner("category")} style={{ cursor: 'pointer' }} className={`${spinWheelRotate && activation ? '' : 'disabled'}`} />
                      </div>
                      <h2>Questions Category</h2>
                    </div>
                    <div className="spin-button">
                      <button onClick={() => { spinWheel() }} className={`${iconClick && spinWheelRotate && active ? '' : 'disabled'}`} > Spin</button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="cus-circle-inner">
                    <div className="circle_grid">
                      <div className="circle-icon">
                        <img src={jury} onClick={() => redirectionToSpinner("jury")} style={{ cursor: 'pointer' }} className={`${spinWheelRotate && activation ? '' : 'disabled'}`} />
                      </div>
                      <h2>Top 12 Winners</h2>
                    </div>
                    <div className="spin-button">
                      <button onClick={() => { juryWheel() }} className={`${iconClick && spinWheelRotate && active ? '' : 'disabled'}`} >Spin </button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <div className="cus-circle-inner">
                    <div className="circle_grid">
                      <div className="circle-icon">
                        <img src={giftbox} onClick={() => redirectionToSpinner("gift")} style={{ cursor: 'pointer' }} className={`${spinWheelRotate && activation ? '' : 'disabled'}`} />
                      </div>
                      <h2>Gifts Category</h2>
                    </div>
                    <div className="spin-button">
                      <button onClick={() => { giftWheel() }} className={`${iconClick && spinWheelRotate && active ? '' : 'disabled'}`} >Spin</button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <div className="next-btn">
          <p onClick={() => tonext()}>Next</p>
        </div> */}
      </div>
    </div >
  );
}
export default Gifts;


// amrik-techindustan
// Amrik@1234