import React, { useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import CustomFormGroup from "../../common/customFormGroup";
import { useHistory } from "react-router-dom";
import circleLogo from "../../../assets/images/circle-logo.png";
import logo from '../../../assets/images/logo.png'
import * as loginAction from './actions'
import { useDispatch, useSelector } from "react-redux"
import axios from 'axios'
import { toast } from '../../../utils'
import loginFormValidation from "../../../validation/loginValidation";
import { useEffect } from "react";

// type User ={
//   email:string,
//   password:string
// }



export default function Login(props) {
  console.log(props, 'props????????????')
  const history = useHistory();
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})


  const { isLoading, User } = useSelector(state => state.User || []);


  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  // const tonext = () => {
  //   history.push("/gifts");
  // };
  const onChangeHandler = (name = "", value = "") => {
    setUser({ ...user, [name]: value });
  };


  const LoginAPI = (props) => {
    console.log(isLoading, 'isLoading')
    const { isValid, newErrors } = loginFormValidation(user);
    if (!isValid) return setErrors(newErrors);

    dispatch(loginAction.login(user)).then(res => {
      toast.success((res && res.message) || 'Login successfully')
      localStorage.setItem('access_token', res.access_token)

      history.push('/episodes')
    }).catch(err => {
      toast.error((err && err.message) || 'Login failed')
    })
  }

  return (
    <div className="main_container">
      <div className="form-container-inner">
        <div className="form-container-con">
          <Grid className="form_Container">
            <div className="cus-circle-logo">
              <img src={circleLogo} />
            </div>
            <div className="form-heading">
              <h2>Welcome Back!</h2>
              <h5>Log In to Your Account</h5>
            </div>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} className="cus-field-rep">
                <CustomFormGroup
                  showEndAdorment={false}
                  type="text"
                  placeholder="Enter Username"
                  className="username_field"
                  value={user.email}
                  onChange={(e) => onChangeHandler("email", e.target.value)}
                  error={!!errors.email}
                  errorMsg={errors.email}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} className="cus-field-rep">
                <CustomFormGroup

                  className="password_field"
                  placeholder="Enter Password"
                  value={user.password}
                  type="password"
                  showEndAdorment
                  error={!!errors.password}
                  errorMsg={errors.password} onChange={(e) => onChangeHandler("password", e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} className="cus-field-rep">
                <p className="forgot-text">Forgot Password?</p>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <button className="login_button" onClick={() => LoginAPI()}>{isLoading ? <div><CircularProgress style={{ color: 'white' }} /></div> : 'Log In'}</button>
              </Grid>
            </Grid>
          </Grid>
          <div className="login-bottom-logo">
            <img src={logo} className="login_logo_image" />
          </div>
          {/* <div className="next-btn">
            <p onClick={() => tonext()}>Next</p> */}
        </div>
      </div>
    </div >
  );
}

