export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS'
export const GET_ALL_CATEGORIES_FAILED = 'GET_ALL_CATEGORIES_FAILED'

export const GET_TOP_12 = 'GET_TOP_12'
export const GET_TOP_12_SUCCESS = 'GET_TOP_12_SUCCESS'
export const GET_TOP_12_FAILED = 'GET_TOP_12_FAILED'

export const GET_GIFTS = 'GET_GIFTS'
export const GET_GIFTS_SUCCESS = 'GET_GIFTS_SUCCESS'
export const GET_GIFTS_FAILED = 'GET_GIFTS_FAILED'

export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_REQUEST_SUCCESS = 'GET_USER_REQUEST_SUCCESS'
export const GET_USER_REQUEST_FAILED = 'GET_USER_REQUEST_FAILED'