import {
  getAllCategoriesAPI,
  getQuestionsFromSelectedCategory,
  getTop12WinnersAPI,
  getGiftsForUser,
  getUserFromTop12Winners
} from "./api"
import types from '../../../types/index'

export function getAllCategories(data) {
  return (dispatch: any) => {
    dispatch({
      type: types.GET_ALL_CATEGORIES
    })
    return new Promise((resolve, reject) => {
      getAllCategoriesAPI(data).then((res: any) => {
        dispatch({
          type: types.GET_ALL_CATEGORIES_SUCCESS,
          payload: res && res.categories || []
        })
        resolve(res)
      }).catch(err => {
        dispatch({
          type: types.GET_ALL_CATEGORIES_FAILED,
        })
        reject(err)
      })
    })
  }
}

export function getTop12Winners(id) {
  return dispatch => {
    dispatch({
      type: types.GET_TOP_12
    })
    return new Promise((resolve, reject) => {
      getTop12WinnersAPI(id).then((res: any) => {
        dispatch({
          type: types.GET_TOP_12_SUCCESS,
          payload: res
        })
        resolve(res)
      }).catch(err => {
        dispatch({
          type: types.GET_TOP_12_FAILED,
        })
        reject(err)
      })
    })
  }
}

export function getGifts(data) {
  return dispatch => {
    dispatch({
      type: types.GET_GIFTS
    })
    return new Promise((resolve, reject) => {
      getGiftsForUser(data).then((res: any) => {
        dispatch({
          type: types.GET_GIFTS_SUCCESS,
          payload: res || []
        })
        resolve(res)
      }).catch(err => {
        dispatch({
          type: types.GET_GIFTS_FAILED,
        })
        reject(err)
      })
    })
  }
}



export function getUserFromTop12WinnersAPI(data: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.GET_QUESTION_REQUEST
    })
    return new Promise((resolve, reject) => {
      getUserFromTop12Winners(data).then((res: any) => {
        dispatch({
          type: types.GET_QUESTION_REQUEST_SUCCESS,
          payload: res
        })
        resolve(res)
      }).catch(err => {
        dispatch({
          type: types.GET_QUESTION_REQUEST_FAILED,
        })
        reject(err)
      })
    })
  }
}

export function getQuestionsFromSelectedCategoryAPI(id, data) {
  return dispatch => {
    dispatch({
      type: types.GET_USER_REQUEST
    })
    return new Promise((resolve, reject) => {
      getQuestionsFromSelectedCategory(id, data).then((res: any) => {
        dispatch({
          type: types.GET_USER_REQUEST_SUCCESS,
          payload: res
        })
        resolve(res)
      }).catch(err => {
        dispatch({
          type: types.GET_USER_REQUEST_FAILED,
        })
        reject(err)
      })
    })
  }
}