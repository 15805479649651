import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import circleLogo from "../../../assets/images/circle-logo.png";
import logo from '../../../assets/images/logo.png'
import { useHistory } from 'react-router-dom'

export default function PageNotFound() {
    const router = useHistory()
    const goToHome = () => {
        router.push('/')
    }

    return (
        <div className="main_container">
            <div className="form-container-inner">
                <div className="form-container-con">
                    <Grid className="form_Container">
                        <div className="cus-circle-logo">
                            <img src={circleLogo} />
                        </div>
                        <div className="form-heading">
                            <h2> Page Not Found</h2>
                        </div>
                        <br />
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <button className="login_button" onClick={() => goToHome()}>Home</button>
                        </Grid>
                    </Grid>

                    <div className="login-bottom-logo">
                        <img src={logo} className="login_logo_image" />
                    </div>

                </div>
            </div>
        </div >
    )
}

