import {
  loginRequestAPI
} from "./api"
import types from '../../../types/index'

interface UserData {
  email: string;
  password: string;
}
export function login(data: UserData) {

  return (dispatch: any) => {
    dispatch({
      type: types.LOGIN_REQUEST
    })
    return new Promise((resolve: any, reject: any) => {

      loginRequestAPI(data).then(res => {
        dispatch({
          type: types.LOGIN_REQUEST_SUCCESS,
          payload: res
        })
        return resolve(res)
      }).catch(err => {
        dispatch({
          type: types.LOGIN_REQUEST_FAILED
        })
        return reject(err)
      })
    })
  }
}