import { apiGet, apiPost } from "../../../utils"
import { useDispatch, useSelector } from "react-redux"

// const baseurl ="https://b4qbfyrdw3.execute-api.eu-north-1.amazonaws.com/dev"
// const token=localStorage.getItem('')

export function getAllCategoriesAPI(data) {
  return apiGet('/api/host/category', data, {}, {})
}
export function getTop12WinnersAPI(params) {
  return apiGet(`/api/results/top_12?episode_id=${params.id}&is_live=true`)
}

export function getGiftsForUser(data) {
  return apiGet(`/api/host/gift`, data, {}, {})
}

export function getQuestionsFromSelectedCategory(params, id) {
  return apiGet(`/api/host/${id}/question?channel_id=${params.channel_id}&episode_id=${params.id}`)
}

export function getUserFromTop12Winners(data) {
  return apiPost(`/api/host/gift/winner`, data)
}

// export function getGiftsFromGiftWheel(data) {
//   return apiGet(`/api/host/${id}/question?channel_id=${params.channel_id}&episode_id=${params.id}`)
// }
