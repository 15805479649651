import React, { useState, useEffect } from "react";
import logo from "../../../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import {
  CircularProgress
} from '@material-ui/core';
import giftbox from "../../../../assets/images/giftbox.svg";
import famous from '../../../../assets/images/famous.svg'
import science from '../../../../assets/images/science.svg'
import tornado from '../../../../assets/images/tornado.svg'
import geography from '../../../../assets/images/geography.svg'
import culture from '../../../../assets/images/culture.svg'
import { useDispatch, useSelector } from "react-redux"
import { useForkRef } from "@material-ui/core";
import * as categoriesActions from '../action'
import globe from "../../../../assets/images/globe.svg";
import Layout from "../../../common/Layout";

import { ws } from "../../../../ws";
import { logOut } from "../../../../utils";

// import '../common/Spinner/spinner.css'
const initialWheel = [
  {
    id: 1,
    className: "span1",
    title: 'Survival',
    image: science
  },
  {
    id: 2,
    className: "span2",
    title: 'Math',
    image: tornado
  },
  {
    id: 3,
    className: "span3",
    title: 'General Culture',
    image: famous
  },
  {
    id: 4,
    className: "span4",
    title: 'Cinema',
    image: science
  },
  {
    id: 5,
    className: "span5",
    title: 'Challenger',
    image: tornado
  },
  {
    id: 6,
    className: "span6",
    title: 'Cinema',
    image: culture
  },
  {
    id: 7,
    className: "span7",
    title: 'Challenger',
    image: culture
  },
  {
    id: 8,
    className: "span8",
    title: 'Survival',
    image: geography
  },
  {
    id: 9,
    className: "span9",
    title: 'Sports',
    image: science
  },
  {
    id: 10,
    className: "span10",
    title: 'Famous',
    image: culture
  },

  {
    id: 11,
    className: "span11",
    title: 'Math',
    image: tornado
  },
  {
    id: 12,
    className: "span12",
    title: 'My Challenge',
    image: geography
  },

]

function getTextToDisplay(text = "") {
  if (typeof text !== 'string') return
  let textArr = text && text.split(' ');

  if (text.length > 14 && textArr.length > 1) {
    return textArr.join('<br /> ')
  }
  return text
}


function SecondSpinner(props) {
  const dispatch = useDispatch()

  const { socketData } = useSelector(state => state.common)
  const { gifts = [] } = useSelector(state => state.gift || {});
  console.log(gifts, 'gifts>>>>>')
  const [catHeading, setCatHeading] = useState('')
  const [text, setText] = useState('')

  const [name, setName] = useState("wheel");
  const [selectedOption, setSelectedOption] = useState(null);
  const [response, setResponse] = useState('')
  const [spinnerStyle, setSpinnerStyle] = useState({})

  const history = useHistory();
  let cat = gifts.gifts && gifts.gifts.length ? gifts.gifts.map((item) => {
    return { title: item.giftCategoryName, id: item.id }
  }) : []

  useEffect(() => {

    if (socketData && socketData.data == "spin the giftWheel" && socketData.id == episode_id) {
      setCatHeading('')
      startRotation()
      setText('')
    }
  }, [socketData])

  let wheelNames = [...initialWheel]


  wheelNames = wheelNames.map((item, i) => {
    const category = cat[i] || {};
    return {
      ...item,
      id: category && category.id || item.id,
      title: category && category.title && getTextToDisplay(category.title) || item.title,
    }
  });

  useEffect(() => {
    setExistValue(wheelNames)
  }, [gifts])

  const LetNumbers = [...wheelNames]
  const [existValue, setExistValue] = useState([...wheelNames]);

  const startRotation = () => {
    setName("wheel start-rotate");
    let updateExistArr = existValue.length > 0 ? [...existValue] : [...wheelNames];
    setSelectedOption(null)
    setTimeout(() => {
      setName("wheel start-rotate stop-rotate");

      // Actual Logic
      let gotValue = updateExistArr[Math.floor(Math.random() * updateExistArr.length)];

      // temp logic
      // let gotValue = updateExistArr[0];

      setCatHeading(gotValue && gotValue.title || '')
      const giftId = gotValue && gotValue.id || ''


      let findInd = LetNumbers.findIndex(val => val.className == gotValue.className);
      let findInd2 = updateExistArr.findIndex(val => val.className == gotValue.className);
      updateExistArr.splice(findInd2, 1);

      setExistValue(updateExistArr);
      setSelectedOption(findInd);

      let params = JSON.parse(localStorage.getItem('channel_id'))
      let userId = localStorage.getItem('userId')
      let user = {
        episode_id: params.id,
        user_id: userId,
        gift_id: giftId
      }
      dispatch(categoriesActions.getUserFromTop12WinnersAPI({ ...user }))
        .then(res => {
          console.log(res, 'res')
          ws.send(JSON.stringify({
            action: "onMessage",
            message: res
          }))
        })
        .catch((res) => {
          setText("Gifts Not Available")
          ws.send(JSON.stringify({
            action: "onMessage",
            message: JSON.stringify({ data: "active buttons" })
          }));
        })

    }, Math.floor(Math.random() * 10000) + 1);
  };
  let episode_id = localStorage.getItem('id')



  useEffect(() => {
    dispatch(categoriesActions.getGifts({ limit: 12 }))
      .then(res => {
        setResponse(res)
        ws.send(JSON.stringify({
          action: "onMessage",
          message: res
        }))
      })
      .catch((res) => {
        setResponse("Questions Not Available")
        ws.send(JSON.stringify({
          action: "onMessage",
          message: JSON.stringify({ data: "active buttons" })
        }));
      })
  }, [])

  const winnerName = response && response.gifts && response.gifts.map((item) => { return item })
  const { } = spinnerStyle || {}

  const isLength = Object.keys(spinnerStyle).length

  // if (isLoading) return <CircularProgress size={40} style={{ display: 'flex', alignItems: 'center', marginLeft: '45%', marginTop: '20%' }} />;

  return (
    <Layout >
      <div className="main_container">
        <div className="cus-logo-sec">
          <img src={logo} className="logo_image" />
        </div>
        {catHeading && <h1 className="catHeading">{catHeading.replace(/<[^>]+>/g, '')}</h1>}
        {text && <h1 className="catHeading">{text}</h1>}
        <div className="spinner-div">
          <ul className={name} id="spinner-ul">

            {
              Array.isArray(winnerName) && winnerName.length ?

                winnerName.map((item, index) => {

                  return <li key={index} className={selectedOption !== null ? selectedOption == index ? 'selected-list' : 'fade-list' : ""}>
                    <span className="text">{item.giftCategoryName || ''}</span>
                    <img className="image" src={item.image || ''} />
                  </li>
                })
                :
                wheelNames.map((item, index) => {

                  return <li key={index} className={selectedOption !== null ? selectedOption == index ? 'selected-list' : 'fade-list' : ""}>
                    <span className="text">{item.title}</span>
                    <img className="image" style={isLength ? spinnerStyle[index].image : {}} src={item.image} />
                  </li>
                })
            }
          </ul>
          <div className="spin-center-second">
            <img src={globe} />
          </div>
        </div>
      </div >
    </Layout >
  );
}
export default SecondSpinner;
