import Joi from "joi";

export default function loginFormValidation(data) {
    let isValid = true
    let newErrors = {}

    const schema = Joi.object({
        email: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .messages({
                "string.empty": `Email is required`,
                "string.email": "Please login with valid credentials",
            }),
        password: Joi.string()
            .required()
            .messages({
                "string.empty": `Password is required`
            }),
    }).validate(data, { abortEarly: false })

    const { error } = schema
    if (error) {
        isValid = false
        error.details.forEach(item => newErrors[item.context.key] = item.message)
    }
    return { isValid, newErrors }
}
