import {
    getAllEpisodesApi
} from "./api"
import types from '../../../types/index'

export function getAllEpisodes(data) {

    return (dispatch) => {
        dispatch({
            type: types.GET_ALL_EPISODES
        })
        return new Promise((resolve, reject) => {

            getAllEpisodesApi(data).then(res => {
                dispatch({
                    type: types.GET_ALL_EPISODES_SUCCESS,
                    payload: res
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: types.GET_ALL_EPISODES_FAILED
                })
                return reject(err)
            })
        })
    }
}