import React from 'react';
import './assets/scss/index.scss'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import configureStore from '../src/Redux/configureStore';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './ws/index';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#161b5f',
    },
    secondary: {
      main: '#292929',
    },
  },
});

const store = configureStore({});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
