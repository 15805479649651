
import { saveObject } from '../../../utils';
import types from '../../../types'


const initalState = {
  isLoading: false,
  categories: [],
  questions: {},
  gifts: [],
  winners: []
}


export default function (state = { ...initalState }, action) {
  switch (action.type) {
    case types.GET_ALL_CATEGORIES:
      return { ...state, isLoading: true }

    case types.GET_ALL_CATEGORIES_SUCCESS:
      return { ...state, isLoading: false, categories: action.payload || [] }
    case types.GET_ALL_CATEGORIES_FAILED:
      return { ...state, isLoading: false }

    case types.GET_QUESTION_REQUEST:
      return { ...state }

    case types.GET_QUESTION_REQUEST_SUCCESS:
      return { ...state, questions: action.payload || {} }

    case types.GET_QUESTION_REQUEST_FAILED:
      return { ...state, }


    case types.GET_TOP_12:
      return { ...state, isLoading: true }
    case types.GET_TOP_12_SUCCESS:
      return { ...state, isLoading: false, winners: action.payload || [] }
    case types.GET_TOP_12_FAILED:
      return { ...state, isLoading: false }

    case types.GET_GIFTS:
      return { ...state, isLoading: true }
    case types.GET_GIFTS_SUCCESS:
      return { ...state, isLoading: false, gifts: action.payload || [] }
    case types.GET_GIFTS_FAILED:
      return { ...state, isLoading: false }


    default:
      return state
  }
}



