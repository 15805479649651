
// import React from 'react'
// type Props ={
//   placeholder:any,
//   className:any,
//   value:any,
//   onChange: (onChange:any)=>void,
//   type:any,
//   showEndAdorment:boolean
// }
// export default function customFormGroup(props: Props) {
//   return (
//     <div>
//       hi
//     </div>
//   )
// }



import React, { useState } from 'react'
import { FormGroup, InputLabel, TextField, OutlinedInput, InputAdornment, IconButton, CircularProgress } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

type Props = {
  placeholder?: string,
  className: string,
  value: string | { [key: string]: any },
  onChange: (onChange: any) => void,
  type: any,
  showEndAdorment: boolean
  name?: string,
  variant?: string,
  fullWidth?: boolean,
  error?: boolean,
  style?: {},
  errorMsg?: string,
  number?: boolean,
  multiple?: boolean,
  loading?: boolean,
  options?: { [key: string]: any }[],
  // label:any,
  noOptionsText?: string
}

function CustomFormGroup(props: Props) {

  const {
    name = '',
    variant = 'outlined',
    fullWidth = true,
    placeholder = '',
    error = false,
    style = {},
    type = 'text',
    errorMsg = '',
    value = '',
    number = false,
    onChange = () => { },
    multiple = true,
    options = [],
    showEndAdorment = false,
    loading = false,
    noOptionsText = 'No options',
    ...restProps
  } = props;

  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      {type === 'text' ?
        <FormGroup className="on-form-group">
          {name ? <InputLabel className="on-form-label">{name}</InputLabel> : null}
          <OutlinedInput
            fullWidth={fullWidth}
            placeholder={placeholder}
            error={error}
            value={value}
            name={name}
            onChange={onChange}
            {...restProps}

            type={number ? 'number' : 'text'}
          />
          {error ? <span className="error-messages" style={{ color: 'red' }}>{errorMsg}</span> : null}
        </FormGroup>
        : type === 'password' ?
          <FormGroup className="on-form-group">
            <InputLabel className="on-form-label">{name}</InputLabel>
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              // variant={variant as any }
              fullWidth={fullWidth}
              placeholder={placeholder}
              error={error}
              value={value}
              name={name}
              onChange={onChange}
              endAdornment={
                showEndAdorment ? <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(prev => !prev)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment> : null
              }
            />
            {error ? <span className="error-messages" style={{ color: 'red' }} >{errorMsg}</span> : null}
          </FormGroup>

          : type === 'textarea' ?
            <FormGroup className="on-form-group">
              <InputLabel className="on-form-label">{name}</InputLabel>
              <textarea
                style={style}
                className={`textarea-input-field ${error ? 'error-active' : ''}`}
                placeholder={placeholder}
                value={value as unknown as string}
                onChange={onChange}
              />
              {error ? <span className="error-messages" style={{ color: 'red' }}>{errorMsg}</span> : null}
            </FormGroup>

            : type === 'select' ?

              <FormGroup className="on-form-group">
                <InputLabel className="on-form-label">{name}</InputLabel>
                <Autocomplete
                  multiple={multiple}
                  fullWidth
                  options={options}
                  onChange={onChange}
                  value={value}
                  noOptionsText={noOptionsText}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) => option.value === value.value}
                  limitTags={6}
                  loading={loading}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      {option.label}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={placeholder}
                      error={error}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={18} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {error ? <span className="error-messages" style={{ color: 'red' }}>{errorMsg}</span> : null}
              </FormGroup> : null}
    </>
  )
}

export default CustomFormGroup
