
import { saveObject } from '../../../utils';
import types from '../../../types'


const initalState = {
    isLoading: false,
    episodes: [],
}


export default function (state = { ...initalState }, action) {
    switch (action.type) {

        case types.GET_ALL_EPISODES:
            return { ...state, isLoading: true }

        case types.GET_ALL_EPISODES_SUCCESS:
            console.log(action.payload, 'action.payload')
            let data = action.payload.episodes ? [...action.payload.episodes] : []
            return { ...state, isLoading: false, episodes: [...data] }

        case types.GET_ALL_EPISODES_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}



